.App {
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#root, .App {
    min-width: 460px;
}

.appHeader {
    background-color: #21252e;
    min-height: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(12px + 2vmin);
    color: white;
    left: 0;
}

.information, .dataInformation {
    text-align: left;
}

.information {
    margin: 3vh 20%;
}

.dataInformation {
    margin: 0 1%;
}

.welcomeInformation {
    margin-top: 4vh;
    margin-bottom: 3vh;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
